.hero-part {
  padding-top: 70px;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;

  .hero-img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }
  .hero-img-responsive {
    height: 10vh;
    width: 100vw;
    display: none;

    .hero-img-hidden {
      width: 100%;
      pointer-events: none;
      user-select: none;
    }
  }

  .hero-img-container {
    max-width: 50%;
    max-height: 95%;

    .hero-img {
      width: 100%;
      height: 100%;
    }
  }

  .hero {
    max-width: 40%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .hero-item:not(:last-child) {
      margin-bottom: 25px;
    }

    .hero-heading {
      font-size: 56px;
      line-height: 70px;
    }

    .hero-text {
      font-size: 20px;
      line-height: 30px;
    }

    .hero-button-container {
      width: 235px;
      height: 40px;

      .hero-button {
        height: 100%;
        width: 100%;
        background-color: #04ba70;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hero-part {
    padding-top: 50px;

    .hero-img-container {
      max-width: 55%;
    }

    .hero {
      .hero-item:not(:last-child) {
        margin-bottom: 20px;
      }

      .hero-heading {
        font-size: 48px;
        line-height: 60px;
      }

      .hero-text {
        font-size: 18px;
        line-height: 25px;
      }

      .hero-button-container {
        width: 205px;

        .hero-button {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero-part {
    padding-top: 50px;

    .hero {
      .hero-heading {
        font-size: 40px;
        line-height: 50px;
      }

      .hero-text {
        font-size: 16px;
        line-height: 25px;
      }

      .hero-button-container {
        width: 175px;
        height: 30px;

        .hero-button {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .hero-part {
    padding-top: 50px;
    flex-direction: column;
    align-items: center;

    .hero-img-responsive {
      display: initial;
    }

    .hero-img-container {
      display: none;
    }

    .hero {
      max-width: 80%;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      text-align: center;

      .hero-heading {
        font-size: 36px;
        line-height: 40px;
      }

      .hero-text {
        font-size: 16px;
        line-height: 20px;
      }

      .hero-button-container {
        width: 150px;
        height: 30px;

        .hero-button {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 481px) {
  .hero-part {
    padding-top: 25px;
    .hero {
      max-width: 90%;
      margin-bottom: 50px;
      .hero-heading {
        line-height: 35px;
      }
    }
  }
}
