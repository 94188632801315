.log-in {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 60px;

  .log-in-container {
    width: 100%;

    .password-reset-button-container {
      display: flex;
      justify-content: center;
      .password-reset-button {
        text-decoration: none;
        color: var(--toastify-color-dark);
        font-size: 18px;
      }
    }

    .log-in-heading {
      font-size: 48px;
    }
  }
}
.log-in-pacs {
  position: absolute;
  bottom: 0;
  z-index: 2;

  width: 100vw;
  max-height: 40vh;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 4000px) {
  .log-in {
    margin-top: 350px;
  }
}
@media screen and (max-width: 2000px) {
  .log-in {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1500px) {
  .log-in {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .log-in {
    margin-top: 30px;
  }
}

@media screen and (max-width: 769px) {
  .log-in {
    margin-top: 50px;
    overflow: hidden;
    .log-in-container {
      .log-in-heading {
        font-size: 40px;
      }
    }
  }
}
