.account {
  margin-top: 5%;
  height: 70vh;
  width: 80%;
  justify-content: space-between;

  .account-cards {
    height: 100%;
    width: 40%;
    max-height: 900px;
  }
}

@media screen and (max-width: 768px) {
  .account {
    justify-content: center;
    .account-cards {
      display: none;
    }
  }
}
