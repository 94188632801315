.proxy-form {
  width: 100%;
  margin-bottom: 20px;

  .search-proxy {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .proxy-fields-container {
    display: flex;
  }
}

.input-container {
  margin-bottom: 25px;
}
