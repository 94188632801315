.password-reset {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .password-reset-heading {
    font-size: 48px;
  }
}
@media screen and (max-width: 480px) {
  .password-reset {
    .password-reset-heading {
      font-size: 42px;
    }
  }
}

