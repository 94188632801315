.redactor-container {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 20px;

  .heading {
    width: 600px;
    display: flex;

    box-sizing: border-box;
    border-bottom: 2px solid black;
  }

  padding: 20px 0;
}
