.edit-profile {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .edit-profile-heading {
    font-size: 40px;
  }
  .new-password {
    color: var(--toastify-color-dark);
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .edit-profile {
    margin-top: 50px;
  }
}
