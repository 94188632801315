.account-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  gap: 20px;
  margin-bottom: 20px;

  .account-data-heading-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .account-data-heading {
      font-size: 25px;
      margin-bottom: -15px;
      text-align: center;
    }
  }

  .account-button {
    margin-top: 5px;
  }
}
