@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@900&family=Karla:wght@400;800&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 245, 245);

  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

:root {
  --background-primary: #121212;
  --background-secondary: #1a1a1a;
  --background-additional: #333333;
  --color-primary: #f5f5f5;
  --color-dark: #191919;
  --color-low-white: #cfcfcf;
  --color-secondary: #ffb300;
  --color-warning: #ff2200;
  --color-success: #04ba70;
}

*::selection {
  background-color: rgb(25, 25, 25);
  color: rgb(245, 245, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.ul {
  list-style: none;
}

.s-font {
  font-family: 'Inconsolata', monospace;
}

.b-font {
  font-family: 'Baloo Thambi 2', cursive;
}

.cairo {
  font-family: 'Cairo', sans-serif;
  color: rgb(25, 25, 25);
}

.karla {
  font-family: 'Karla', sans-serif;
  font-weight: 400;
}

.karla-bold {
  font-family: 'Karla', sans-serif;
  font-weight: 800;
}

.f-16 {
  font-size: 16px;
}

.no-decorators {
  text-decoration: none;
}

.white {
  color: white;
}

.black {
  color: rgb(25, 25, 25);
}

.full-width {
  width: 100%;
}

.is-nine-tenth {
  width: 90%;
}

.is-four-fifth {
  width: 80%;
}

.is-three-quaters {
  width: 75%;
}

.is-half {
  width: 50%;
}

.no-scroll-page {
  height: calc(100vh - 90px);
}

.is-offset-one-twenty {
  margin-left: 5%;
}

.is-offset-one-quater {
  margin-left: 25%;
}

.is-offset-one-eights {
  margin-left: 12.5%;
}

.is-offset-one-tenth {
  margin-left: 10%;
}

.section {
  padding: 3rem 1.5rem;
  padding-bottom: 60px;
}

.section-footer {
  padding: 3rem 1.5rem;
  padding-bottom: 10px;
  padding-top: 10px;
}

.section-page {
  padding: 3rem 1.5rem;
  padding-top: 0;
}

.subtitle {
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:before {
    display: block;
    text-align: center;
    padding-bottom: 12px;
    margin-right: 20px;
  }
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-negative-70 {
  margin-bottom: -70px;
}

.full-height {
  height: calc(100vh - 3rem - 107px);
}

.index-high {
  z-index: 100;
}

.overflow-hidden {
  overflow: hidden;
}

.is-column {
  display: flex;
  flex-direction: column;
}

.is-row {
  display: flex;
  flex-direction: row;
}

.content-between {
  display: flex;
  justify-content: space-between;
}

.content-center {
  display: flex;
  justify-content: center;
}

.content-start {
  display: flex;
  justify-content: flex-start;
}

.content-end {
  display: flex;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1201px) {
  .section {
    padding: 2rem 1rem;
  }
  .pc-hello {
    width: 600px;
  }
  .register-pc {
    width: 325px;
  }
}

@media only screen and (max-width: 1024px) {
  .margin-bottom-100 {
    margin-bottom: 20px;
  }

  .pc-hello {
    width: 500px;
  }
  .register-pc {
    width: 300px;
  }
}

@media only screen and (max-width: 901px) {
  .pc-hello {
    width: 450px;
  }
  .register-pc {
    width: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .pc-hello {
    width: 350px;
  }
}

@media only screen and (max-width: 690px) {
  #layout-wrapper {
    height: 100vh;
    width: 100vw;
    .under-development {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #layout-section {
      display: none;
    }
  }
}
