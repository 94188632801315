.account-links {
  display: flex;
  justify-content: flex-end;
  .account-link {
    text-decoration: none;
    color: var(--toastify-color-dark);

    &:not(:last-child) {
      width: 100px;
      margin-right: 7.5%;
    }
  }
}
