.mouse {
  margin-top: 50px;
  margin-bottom: 50px;
  pointer-events: none;
  user-select: none;

  .mouse-container {
    width: 34px;
    height: 55px;
  }
  .mouse-icon {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 3px solid rgb(25, 25, 25);
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #04ba70;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .mouse {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 481px) {
  .mouse {
    margin-top: 20px;
  }
}

@media screen and (max-width: 300px) {
  .mouse {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
