.page-not-found {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;

  .not-found-img {
    height: 110vh;
    width: 110vw;
    position: absolute;
    top: -5vh;
    pointer-events: none;
    user-select: none;

    filter: blur(5px);

    object-fit: cover;
    object-position: 65% 0;
    pointer-events: none;
  }

  .not-found-text {
    z-index: 1;
    font-size: 6.2vw;

    &::selection {
      background: rgba(35, 35, 35, 0.538) !important;
    }
  }

  .not-found-button {
    width: 150px;
    height: 40px;
    background-color: rgb(25, 25, 25);
    border-radius: 10px;
    z-index: 1;
    border: none;
  }
}
