.article-form {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 50px;

  .fields-container {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 50%;

    .inputs-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    .article-text-area {
      width: 100%;
      height: 100%;
      display: flex;
      box-sizing: border-box;

      max-width: 500px;
      max-height: 135px;

      min-width: 240px;
      min-height: 135px;

      padding: 5px;
    }
  }

  @media screen and (max-width: 1060px) {
    .fields-container {
      flex-direction: column;
      align-items: center;
    }

    .inputs-container {
      align-items: center;
    }
  }

  & > .btn-container {
    display: flex;
    justify-content: space-between;
  }
}
