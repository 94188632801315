.subscriptions {
  width: 100vw;
  margin-top: 20px;

  .subscriptions-heading {
    font-size: 48px;
    margin-bottom: 30px;
    padding-top: 2.5%;
  }

  .subscriptions-cards {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  .subscriptions {
    height: 1200px;
    margin-bottom: 100px;
    .subscriptions-heading {
      font-size: 40px;
      padding-top: 10%;
    }
    .subscriptions-cards {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .subscriptions {
    height: 1200px;
    margin-bottom: 80px;
    .subscriptions-heading {
      font-size: 28px;
      padding-top: 10%;
    }
    .subscriptions-cards {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
    }
  }
}
@media screen and (max-width: 425px) {
  .subscriptions {
    margin-bottom: 60px;

    .subscriptions-heading {
      font-size: 30px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .subscriptions-cards {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
    }
  }
}
