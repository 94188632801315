.footer {
  position: relative;
  height: 70px;
  width: 100%;
  .footer-line {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid rgb(25, 25, 25);
  }

  .footer-content {
    height: 100%;
    .footer-logo-container {
      .footer-logo {
        height: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        user-select: none;
      }
    }

    .footer-links {
      .footer-link {
        margin-right: 40px;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .footer {
    height: 75px;
    .footer-content {
      .footer-rights {
        font-size: 12px;
      }
      .footer-links {
        &:not(:last-child) {
          margin-right: 30px;
        }
        .footer-link {
          margin-right: 10px;
        }
        .footer-buttons {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 380px) {
  .footer {
    height: 100px;
    .footer-content {
      .footer-logo-container {
        .footer-logo {
          height: 30%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
          user-select: none;
        }
      }
    }
    .footer-links {
      .faq {
        position: absolute;
        left: 40px;
      }
    }
    .footer-rights {
      position: absolute;
      left: 36%;
      top: 75px;
    }
  }
}
