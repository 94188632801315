.mr {
  margin-right: 30px;
}
.price-card {
  width: 30%;

  .card-icon {
    margin-bottom: 20px;
  }

  .icon-width-small {
    width: 65px;
    pointer-events: none;
    user-select: none;
  }

  .icon-width-medium {
    width: 145px;
    pointer-events: none;
    user-select: none;
  }

  .icon-width-standard {
    width: 285px;
    pointer-events: none;
    user-select: none;
  }

  .price-title {
    font-size: 36px;
    margin-bottom: 5px;
  }

  .price-subtitle {
    font-size: 24px;
  }

  .color-dark-blue {
    color: #074151;
  }

  .color-yellow {
    color: #fdc727;
  }

  .price-list {
    padding-left: 10px;

    .price-card-container {
      display: flex;
      gap: 5px;
      align-items: center;

      .details {
        display: flex;
        margin-top: -6px;
        position: relative;

        width: 17px;
        height: 17px;

        .details-icon-container {
          position: relative;
          width: 100%;
          height: 100%;

          .details-icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
          }

          &:hover {
            cursor: auto;

            .details-content {
              display: block;

              position: absolute;
              right: 0;
              z-index: 3;
            }
          }
        }

        .details-content {
          display: none;
          width: 225px;
          background-color: var(--color-primary);
          border: 1px solid var(--color-success);
          border-radius: 5px;

          .details-container {
            width: 80%;
            height: 80%;
            margin: auto;
            padding: 5px 0;
          }
        }
      }

      .price-list-item {
        font-size: 20px;
        margin-bottom: 10px;

        .price-list-text {
          color: rgb(25, 25, 25);
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .mr {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .mr {
    margin-right: 0;
  }
  .price-card {
    width: 100%;
    .price-list {
      .price-list-item {
        font-size: 18px;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    .price-title {
      font-size: 32px;
    }
    .price-subtitle {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 481px) {
  .price-card {
    .icon-width-small {
      width: 45px;
    }

    .icon-width-medium {
      width: 105px;
    }

    .icon-width-standard {
      width: 185px;
    }
  }
}
