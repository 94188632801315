.sidebar {
  width: 35vw;
  height: 150%;
  min-width: 150px;

  background-color: rgb(245, 245, 245);

  z-index: 9;
  top: 0;
  left: -100vw;
  position: absolute;

  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);

  &.active {
    left: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.316);
  }

  display: flex;
  align-items: center;
  flex-direction: column;

  .sidebar-links {
    width: fit-content;
    gap: 20px;
    margin-top: 150px;

    .header-href {
      width: 100%;
      margin: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .sidebar {
    width: 100vw;
    .sidebar-links {
      margin-top: 150px;

      .header-href {
        text-align: center;
        font-size: 18px;
      }
    }
  }
}
