.account-subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  .account-subscription-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .account-subscription-heading {
      font-size: 25px;
      margin-bottom: -10px;
      text-align: center;
      display: flex;
      align-items: center;
    }

    .account-subtitle {
      font-size: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;
      display: flex;
      align-items: center;
      .account-subtitle-text {
        margin-top: 13px;
      }
    }

    .level-simple {
      content: url('../../../../../assets/account/AccountSimple.svg');
      width: 35px;
      margin-right: 20px;
      pointer-events: none;
      user-select: none;
    }

    .level-medium {
      content: url('../../../../../assets/account/AccountMedium.svg');
      width: 40px;
      margin-right: 10px;
      pointer-events: none;
      user-select: none;
    }

    .level-premium {
      content: url('../../../../../assets/account/AccountPremium.svg');
      width: 40px;
      margin-right: 20px;
      pointer-events: none;
      user-select: none;
    }
  }

  .account-button {
    height: 40px;
    padding: 0 20px;
    border-radius: 10px;
    background-color: rgb(25, 25, 25);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .account-subscription {
    gap: 20px;
    .account-subscription-data {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .account-subtitle {
        .account-subtitle-text {
          margin-top: 0;
        }
      }
      .account-subscription-heading {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .account-subscription {
    .account-subscription-data {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .account-subscription-heading {
        font-size: 20px;
      }
    }
  }
}
