.work-part {
  max-width: 1300px;
  .work-heading {
    font-size: 48px;
    margin-bottom: 30px;
  }

  .work-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .work-card {
      width: 27.5%;
      height: 450px;

      position: relative;

      border: 3px solid rgb(25, 25, 25);
      border-radius: 10px;

      .work-card-container {
        width: 80%;
        height: calc(90% - 20px);

        margin-top: calc(5% + 20px);
        margin-bottom: calc(5% + 20px);
        margin-left: 10%;

        .work-card-text {
          font-size: 20px;
          z-index: 1;
          margin-bottom: 20px;
        }

        .work-button-container {
          width: 100%;
          height: 40px;

          display: flex;
          justify-content: center;

          .work-button {
            width: 150px;
            height: 100%;

            background-color: rgb(25, 25, 25);
            border-radius: 10px;
          }
        }
      }
    }

    .work-card-one {
      .work-card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        height: 90%;
        .work-card-content {
          margin-top: 20px;
          z-index: 2;
          height: 64%;
          margin-left: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .work-card-heading {
            font-size: 32px;
            z-index: 1;
          }
          .work-card-text {
            max-width: 70%;
            margin-left: 60px;
          }
        }
      }
      .img-pac {
        position: absolute;
        top: 25%;
        left: 0;
        max-width: 120px;
        height: 50%;
        pointer-events: none;
        user-select: none;
      }
      .img-pac-small {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 25%;
        pointer-events: none;
        user-select: none;
      }
    }

    .work-card-two {
      .work-card-container {
        position: relative;

        display: flex;
        flex-direction: column;
        .work-card-heading {
          max-width: 80%;

          margin-bottom: 60px;

          font-size: 32px;
          line-height: 30px;
          z-index: 1;
        }
        .work-card-text {
          max-width: 80%;
        }
      }
      .img-snake-vert {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        pointer-events: none;
        user-select: none;
      }

      .img-snake-hor {
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 50%;
        pointer-events: none;
        user-select: none;
      }
    }
    .work-card-three {
      .work-card-container {
        position: relative;

        display: flex;
        flex-direction: column;
        .work-card-heading {
          max-width: 90%;

          margin-bottom: 30px;

          font-size: 32px;
          line-height: 30px;
          z-index: 1;
        }
        .work-card-text {
          max-width: 80%;
        }
      }
      .img-star {
        position: absolute;
        top: -15%;
        right: -10%;
        height: 30%;
        animation: 9s linear infinite rotate;
        z-index: 5;
        pointer-events: none;
        user-select: none;
      }
    }
    .img-pac-half {
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      user-select: none;
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .work-part {
    .work-heading {
      font-size: 48px;
    }
    .work-cards {
      .work-card {
        width: 30%;
        height: 400px;

        .work-card-container {
          .work-card-text {
            font-size: 20px;
          }
        }
      }
      .work-card-two {
        .work-card-container {
          .work-card-heading {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .work-part {
    .work-heading {
      font-size: 40px;
      line-height: 50px;
    }
    .work-cards {
      .work-card {
        width: 30%;
        height: 350px;

        .work-card-container {
          .work-button-container {
            .work-button {
              height: 30px;
            }
          }
          .work-card-text {
            font-size: 16px;
          }
        }
      }
      .work-card-one {
        .work-card-container {
          .work-card-content {
            .work-card-text {
              margin-left: 40px;
            }
          }
        }
      }
      .work-card-two {
        .work-card-container {
          .work-card-heading {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .work-part {
    .work-heading {
      font-size: 36px;
      line-height: 40px;
    }
    .work-cards {
      flex-direction: column;
      align-items: center;
      .work-card {
        width: 90%;
        height: 160px;
        margin-bottom: 25px;
        .work-card-container {
          height: calc(100% - 20px);
          margin-top: 10px;
          margin-bottom: 10px;

          .work-card-heading {
            font-size: 24px;
          }
          .work-card-text {
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }
      .work-card-one {
        .work-card-container {
          height: 95%;
          .work-card-content {
            margin-top: 10px;
            height: 55%;
            .work-card-text {
              margin: 0;
            }
            .work-card-heading {
              font-size: 24px;
            }
          }
        }
      }
      .work-card-two {
        .work-card-container {
          .work-card-heading {
            margin-bottom: 20px;
          }
        }
      }
      .work-card-three {
        .work-card-container {
          .work-card-heading {
            margin-bottom: 10px;
          }
        }
        .img-star {
          right: -6%;
          height: 60%;
        }
      }
    }
  }
}

@media only screen and (max-width: 481px) {
  .work-part {
    .work-heading {
      font-size: 32px;
      margin-bottom: 25px;
    }
    .work-cards {
      .work-card {
        height: 200px;
        margin-bottom: 30px;

        .work-card-container {
          height: calc(100% - 20px);
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 40px;
        }
      }
      .work-card-one {
        .work-card-container {
          .work-card-content {
            margin-left: 20%;
            .work-card-text {
              margin-top: 10px;
            }
          }
        }
      }
      .work-card-three {
        .work-card-container {
          margin-left: 30px;
          .work-card-text {
            max-width: 95%;
          }
          .work-card-heading {
            width: 90%;
          }
        }
        .img-star {
          height: 40%;
          right: -12%;
        }
      }
    }
  }
}

@media only screen and (max-width: 310px) {
  .work-part {
    .work-cards {
      .work-card-one {
        .work-card-container {
          width: 100%;
        }
      }
      .work-card-two {
        .work-card-container {
          width: calc(100% - 20px);
          margin-left: 20px;
        }
      }
      .work-card-three {
        .work-card-container {
          width: calc(100% - 20px);
          margin-left: 20px;
          margin-right: 0;
          .work-card-text {
            overflow: auto;
          }
        }
      }
    }
  }
}
