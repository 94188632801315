.user-form {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pr-form-buttons {
  height: 30px;
  width: 100%;

  display: flex;
  justify-content: flex-end;

  .btn {
    all: unset;

    height: 30px;
    width: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    border-radius: 0px 25px 25px 25px;
    border: none;

    font-size: 17px;
    text-decoration: none;
    color: rgb(245, 245, 245);
    background-color: #ccc1ff;
  }
}
