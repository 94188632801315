.checkout {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 10vh;
  .checkout-img-container {
    width: 50%;
    max-width: 700px;

    .checkout-img {
      width: 100%;
      pointer-events: none;
      user-select: none;
    }
  }

  .checkout-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 50%;
    max-width: 500px;
    padding: 0 10px;
    margin: 0 auto;

    .checkout-level {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .checkout-level-text {
        font-size: 24px;
      }

      .checkout-level-data {
        font-size: 32px;
        text-transform: capitalize;
      }

      .label-premium {
        color: #04ba70;
      }
      .label-medium {
        color: #fdc727;
      }
      .label-simple {
        color: #074151;
      }
    }

    .checkout-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .checkout-price-text {
        font-size: 24px;
      }

      .checkout-price-data {
        font-size: 24px;
        text-transform: capitalize;
      }
    }

    .checkout-payment {
      margin-top: 20px;
      .checkout-payment-text {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .checkout-form {
        display: flex;
        flex-direction: column;

        .checkout-item {
          border: 2px solid rgb(25, 25, 25);
          border-radius: 10px;
          height: 50px;
          display: flex;
          margin-bottom: 20px;
          cursor: pointer;

          .checkout-description {
            font-size: 24px;
            cursor: pointer;
          }

          .checkout-box {
            height: 15px;
            width: 15px;

            margin: 3px 15px 0 25px;
            cursor: pointer;

            appearance: none;

            border-radius: 20%;

            border: 2px solid #999;
            transition: 0.2s all linear;
            outline: none;

            &:checked {
              background-color: rgb(25, 25, 25);
            }
          }
        }

        .checkout-button-container {
          width: 100%;
          height: 40px;

          .checkout-button {
            all: unset;
            cursor: pointer;
            display: flex;
            width: 50%;
            justify-content: center;
            align-items: center;
            background-color: rgb(25, 25, 25);
            color: rgb(245, 245, 245);
            outline: none;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .checkout {
    flex-direction: column-reverse;
    margin-top: 7.5vh;
    .checkout-content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 500px;
      padding: 0 10px;
      margin: 0 auto;
    }

    .checkout-img-container {
      width: 100vw;
      max-width: unset;

      position: absolute;
      bottom: 0;
      left: 0;

      overflow: hidden;

      .checkout-img {
        width: 100vw;

        min-width: 800px;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .checkout {
    .checkout-content {
      .checkout-level {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checkout-level-text {
          font-size: 18px;
        }

        .checkout-level-data {
          font-size: 24px;
          text-transform: capitalize;
        }
      }

      .checkout-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .checkout-price-text {
          font-size: 18px;
        }

        .checkout-price-data {
          font-size: 24px;
          text-transform: capitalize;
        }
      }

      .checkout-payment {
        margin-top: 20px;
        .checkout-payment-text {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .checkout-form {
          display: flex;
          flex-direction: column;

          .checkout-item {
            border: 2px solid rgb(25, 25, 25);
            border-radius: 10px;
            height: 50px;
            display: flex;
            margin-bottom: 20px;
            cursor: pointer;

            .checkout-description {
              font-size: 24px;
              cursor: pointer;
            }

            .checkout-box {
              height: 15px;
              width: 15px;

              margin: 0 10px 0 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
