.proxy-manager-list {
  width: 100%;
  box-sizing: border-box;

  border-radius: 10px;
  border: 2px solid black;

  padding: 20px;
  margin-top: 20px;

  display: flex;
  gap: 10px;
  flex-direction: column;

  overflow-y: auto;
  height: 374px;

  .loading-container {
    width: 100%;
    height: 100%;

    display: flex;
    align-self: center;
    justify-content: center;
  }

  .proxy {
    width: 100%;

    &:not(:last-child) {
      border-bottom: 2px solid var(--background-secondary);
    }

    gap: 5px;
    display: flex;
    flex-direction: column;

    .success {
      color: var(--color-success);
    }
    .warning {
      color: var(--color-warning);
    }
    .secondary {
      color: var(--color-secondary);
    }
    .dark {
      color: var(--color-dark);
    }

    .proxy-header,
    .proxy-settings {
      gap: 20px;
      display: flex;
      flex-direction: row;

      .pwd,
      .port,
      .login,
      .status,
      .address {
        flex: 1;

        font-weight: bold;
      }
    }

    .proxy-header {
      .pwd,
      .port,
      .login,
      .status,
      .address {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
