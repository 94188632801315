.income-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  width: 400px;

  padding: 0;
  margin: 0;

  .title {
    padding-top: 15px;
    font-weight: 500;
    font-size: 24px;
  }

  .income-cards {
    margin: 80px auto;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    gap: 20px;

    .income-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      gap: 15px;

      min-width: 230px;

      .title {
        font-size: 24px;
      }

      .profit {
        font-size: 32px;
      }

      .up {
        font-size: 20px;

        .percent {
          color: var(--color-success);
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .income-container {
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .title {
      font-weight: 500;
      font-size: 32px;
    }

    .income-cards {
      margin: 20px auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      gap: 20px;

      .income-card {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        gap: 15px;

        .title {
          font-size: 24px;
        }

        .profit {
          font-size: 32px;
        }

        .up {
          font-size: 20px;

          .percent {
            color: var(--color-success);
          }
        }
      }
    }
  }
}
