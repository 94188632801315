.header {
  height: 90px;

  .header-container {
    height: 40px;
    width: 100%;
    margin-top: 50px;

    .page-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding-left: 50px;

      font-size: 2rem;
      font-weight: bold;
    }

    .header-box {
      gap: 25px;
    }

    .header-logo-container {
      z-index: 10;

      .header-logo {
        height: 100%;
        pointer-events: none;
        user-select: none;
      }
    }

    .header-href {
      z-index: 1;
      &:not(:last-child) {
        margin-right: 6vw;
      }
    }

    .hidden {
      display: none;
    }

    .header-button {
      width: 150px;
      background-color: rgb(25, 25, 25);
      border-radius: 10px;
      z-index: 1;
      border: none;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    .header-container {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .header {
    .header-container {
      margin-top: 30px;
      .header-box {
        h3 {
          display: none;
        }
      }
    }
  }
}
