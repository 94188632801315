.site-img {
  width: 70vw;
  max-width: 1500px;
  margin-top: 80px;
  margin-bottom: 40px;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 1200px) {
  .site-img {
    margin-top: 60px;
  }
}

@media screen and (max-width: 769px) {
  .site-img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .site-img {
    width: 75vw;
    margin-top: 20px;
  }
}

@media screen and (max-width: 481px) {
  .site-img {
    width: 90vw;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
