.faq-title {
  display: flex;
  font-size: 64px;
  margin: 30px auto;

  &::before,
  &::after {
    display: block;
    transform: scale(0.5);
    content: url('../../../assets/subscription/SubsIconPremiumBonus.svg');
    margin-top: 10px;
    pointer-events: none;
    user-select: none;
  }
}

.faq-search-container {
  position: relative;
  width: 60vw;
  height: 35px;
  max-width: 800px;
  margin-bottom: 15px;

  .faq-search-label {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 15px;
    transform: translateY(-30%);
  }

  .faq-search-field {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 46px);
    max-width: 800px;
    height: 100%;

    border: 2px solid rgba(25, 25, 25);
    border-radius: 5px;
    padding-left: 40px;

    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:focus {
      outline: 2px solid var(--color-success);
    }
  }
}

.faq-not-found {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.faq-article {
  width: 60vw;
  max-width: 800px;

  border: 2px solid rgba(25, 25, 25);
  border-radius: 5px;

  padding: 0px 10px;
  margin: 10px 0;
  box-sizing: border-box;

  transition: all 0.2s cubic-bezier(0.57, 0.21, 0.69, 1.25);

  display: flex;
  flex-direction: column;

  h4 {
    display: flex;
    align-articles: center;
    justify-content: space-between;

    margin: 10px 0;
  }

  span {
    transition: all 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  .faq-article-text-false {
    overflow: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
  }

  .faq-article-text-opened {
    overflow: visible;
    opacity: 1;

    max-width: 100%;
    margin: 10px 0;

    overflow-wrap: break-word;
  }
}

.faq-loading-icon {
  margin-top: 50px;

  width: 50px;
  height: 50px;

  color: var(--color-success);
}

.checkbox-hidden {
  display: none;
}

.select-none {
  user-select: none;
}

.green-label {
  color: var(--toastify-icon-color-success);
  margin-left: 15px;
}

@media screen and (max-width: 780px) {
  .faq-title {
    margin: 10px auto -25px auto;
  }
  .faq-article {
    width: 70vw;
  }
  .faq-search-container {
    width: 70vw;
  }
}
