.article-redactor-container {
  width: 100%;
  min-height: 374px;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;

  & > span {
    margin: 10px auto;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .article-redactor-container {
    width: auto;
  }
}
