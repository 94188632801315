.input-container {
  margin-bottom: 25px;
}

.dropdown-form-container {
  width: 150px;

  .dropdown-form {
    width: 100%;
    min-height: 142px;

    & * {
      user-select: none;
    }

    .dropdown-filter {
      width: 90%;

      border: 2px solid #000;
      border-radius: 5px;
      padding: 5px 10px;

      display: flex;
      justify-content: space-between;

      font-size: 16px;
      color: var(--background-additional);

      cursor: pointer;

      .dropdown-filter-container {
        display: flex;
        gap: 5px;
        .dropdown-filter-value {
          text-transform: capitalize;
          color: #000;
        }
      }
      .arrow-dropdown {
        width: 12px;
      }

      transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .dropdown-active {
      border: 2px solid var(--color-success);
    }

    .dropdown-fields-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;

      border: 2px solid #000;
      border-radius: 5px;

      .dropdown-input-container {
        width: 100%;
        padding: 5px 0;

        &:not(:nth-child(1)) {
          border-top: 2px solid #000;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.215);
          cursor: pointer;
        }
      }

      .dropdown-label {
        width: 100%;
        text-align: center;
      }
    }

    .dropdown-submit {
      display: none;
    }
  }
}
