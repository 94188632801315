.input-container {
  width: 100%;
  max-width: 500px;
  min-width: 350px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  margin-bottom: 30px;

  label {
    width: 75%;
    float: left;
    margin-left: -25px;
    margin-bottom: 5px;
    color: rgb(25, 25, 25);

    cursor: default;
  }

  .field-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .field {
      width: 78%;
      height: 35px;
      font-size: 16px;

      padding-left: 20px;

      border-radius: 10px;
      border: 3px solid rgb(25, 25, 25);
      touch-action: none;
    }

    .full-width-field {
      width: calc(96% - 20px);
      height: 200px;

      padding-left: 20px;
      padding-top: 10px;
      resize: none;

      border-radius: 10px;
      border: 3px solid rgb(25, 25, 25);
      touch-action: none;
    }
    .field-eye {
      position: absolute;
      top: 52.5%;
      right: 13.5%;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
}

.full-width {
  max-width: 1350px;
  label {
    width: 93.5%;
  }
}
