.admin-panel {
  width: 90%;
  min-height: calc(100vh - 220px);
  margin-top: 20px;

  display: flex;
  justify-content: space-between;

  .page-title {
    margin: 0;
    font-size: 4rem;
    font-weight: 900;
    text-align: center;
  }

  .rounded {
    padding: 20px;
    border-radius: 10px;
    border: 2px solid black;
  }

  padding-bottom: 40px;
}

@media screen and (max-width: 1240px) {
  .admin-panel {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
  }
}
