.account-data-container {
  width: 100%;

  .account-data-field {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 18px;
  }
  .account-data-line {
    margin-top: 3px;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: #606060;
  }
}
