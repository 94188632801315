.proxy-manager-container {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  padding: 20px 0;
  .heading {
    align-self: center;
    width: 600px;
    display: flex;

    box-sizing: border-box;
    border-bottom: 2px solid black;
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }
}
