.sign-up {
  .sign-up-heading {
    font-size: 48px;
    position: relative;

    z-index: 2;
  }

  .snake-yellow {
    width: 40%;
    position: absolute;
    top: 7.5%;
    right: 0;
    pointer-events: none;
    user-select: none;
  }

  .snake-green {
    width: 12.5%;
    position: absolute;
    bottom: 0;
    left: 60%;
    pointer-events: none;
    user-select: none;
  }

  .snake-blue {
    width: 10%;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    user-select: none;
  }

  .snake-yellow-green {
    width: 25%;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    user-select: none;
  }
}

@media screen and (max-width: 768px) {
  .sign-up {
    .snake-yellow {
      width: 40%;
      position: absolute;
      top: 11%;
      right: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .sign-up {
    overflow: hidden;
    margin-top: 20px;
    .sign-up-heading {
      font-size: 40px;
      line-height: 60px;
    }
  }
}
