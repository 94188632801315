.menu-btn {
  z-index: 10;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  background: transparent;

  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 5px;

  span {
    width: 30px;
    height: 5px;
    background: rgb(25, 25, 25);

    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.active {
    transform: rotate(90deg);
  }

  .bottom.active {
    transform: translateY(-20px);
    opacity: 0;
  }

  .middle.active {
    background: #04ba70;
    transform: rotate(-45deg);
  }

  .top.active {
    transform: translateY(10px) rotate(45deg);
  }
}
