.loading-icon {
  width: 18px;
  height: 18px;

  display: flex;

  align-items: center;
  justify-content: center;

  animation: 9s linear infinite rotate;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.responsive-icon {
  width: 50%;
  height: 50%;

  margin: auto;
}

.light-color {
  color: var(--color-primary);
}

.dark-color {
  color: var(--color-dark);
}
