.button {
  height: 40px;
  width: max-content;
  min-width: 100px;
  padding: 0 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: none;

  cursor: pointer;

  font-size: 18px;
  color: rgb(245, 245, 245);
  z-index: 1;

  user-select: none;
}

.btn-default {
  background-color: rgb(25, 25, 25);
}

.btn-primary {
  background-color: #04ba70;
}

.btn-trans {
  background-color: transparent;
  color: var(--color-dark);

  &.active {
    color: var(--color-success);
  }
}
