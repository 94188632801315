.blur-container {
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
}

.blur-preventer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.blur-title {
  font-size: 40px;
  position: absolute;

  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;

  width: fit-content;

  z-index: 2;

  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.796));
}

.blur-child {
  width: 100%;
  height: 100%;

  z-index: 1;
  position: relative;

  filter: blur(5px);

  & > * {
    height: 90%;
  }
}
