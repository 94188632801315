.contact-form {
  width: 100%;
  margin-bottom: 30px;

  .field-inputs-row {
    display: flex;
    justify-content: space-between;
  }
  .form-inputs-textarea {
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .contact-form {
    .field-inputs-row {
      flex-direction: column;
      gap: -20px;
    }

    .input-container {
      .full-width-field {
        height: 100px;
      }

      label {
        width: calc(100% - 25px);
      }

      .field {
        width: calc(100% - 30px);
      }
    }
  }
}
