.error-message {
  position: absolute;
  bottom: -20px;
  left: 12.5%;

  color: red;
  font-size: 14px;
}

.full-width-error {
  width: 100%;
  left: 35px;
}

@media screen and (max-width: 480px) {
  .error-message {
    left: 5%;
  }
}
