.contact {
  width: 75%;
  margin-top: 20vh;
  .contact-header {
    font-size: 48px;
  }

  .field-inputs-row {
    width: 102%;
  }
}

@media screen and (max-width: 2000px) {
  .contact {
    margin-top: 8vh;
  }
}

@media screen and (max-width: 1200px) {
  .contact {
    margin-top: 3vh;
  }
}

@media screen and (max-width: 1024px) {
  .contact {
    .contact-header {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 769px) {
  .contact {
    .contact-header {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 680px) {
  .contact {
    .contact-header {
      line-height: 60px;
    }
    .field-inputs-row {
      width: 100%;
    }
  }
}

@media screen and (max-width: 680px) {
  .contact {
    margin-top: 0;
  }
}
