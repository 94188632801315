.navigation-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0;
  margin: 0;

  width: 70vw;

  .links-container {
    display: flex;
    justify-content: space-around;

    padding: 20px;
    box-sizing: border-box;

    width: 600px;

    a {
      font-size: 24px;
      text-decoration: none;
      color: var(--color-dark);

      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    a.active {
      color: var(--color-success);
    }
  }
}

@media screen and (min-width: 1240px) {
  .navigation-container {
    width: calc(100% - 450px);
  }
}
