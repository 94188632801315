.price {
  max-width: 1300px;

  margin-bottom: 20px;
  .price-heading {
    font-size: 48px;
    margin-bottom: 40px;
  }

  .price-cards {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .price {
    .price-heading {
      font-size: 48px;
      line-height: 60px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .price {
    .price-heading {
      font-size: 40px;
      line-height: 50px;
    }

    .price-cards {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }
}

@media only screen and (max-width: 769px) {
  .price {
    .price-heading {
      font-size: 40px;
      line-height: 40px;
    }
  }
}

@media only screen and (max-width: 481px) {
  .price {
    .price-heading {
      font-size: 32px;
      line-height: 35px;
      margin-top: 35px;
      margin-bottom: 25px;
    }
  }
}
