.account-card {
  height: 45%;
  width: 100%;
  max-width: 600px;
  min-width: 280px;
  max-height: 400px;

  border: 3px solid rgb(25, 25, 25);
  border-radius: 10px;

  position: relative;
  overflow: hidden;

  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.701);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.701);
    transform: translateY(2px);
    backface-visibility: hidden;
  }

  .account-card-container {
    height: calc(100% + 6px);
    top: -3px;
    left: 3px;
    width: calc(100% + 3px);
    position: relative;
    overflow: hidden;

    box-sizing: border-box;

    padding: 20px 40% 20px 40px;
    .card-title {
      font-size: 36px;
      line-height: 35px;
    }

    .card-button {
      background-color: rgb(25, 25, 25);
      border: none;
      border-radius: 10px;
      height: 40px;
      font-size: 16px;
      z-index: 5;

      &:hover {
        cursor: pointer;
      }
    }

    .horizontal-snake {
      position: absolute;
      bottom: -38px;
      right: -24px;
      z-index: 3;
      pointer-events: none;
      user-select: none;

      filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.5));
    }

    .vertical-snake {
      position: absolute;
      height: calc(100% - 6px);
      top: 3px;
      right: 20px;
      pointer-events: none;
      user-select: none;

      filter: drop-shadow(0px 0px 5px rgb(0, 0, 0));
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .account-card {
    .account-card-container {
      .card-title {
        font-size: 31px;
      }
      .card-button {
        margin-bottom: 50%;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 769px) {
  .account-card {
    .account-card-container {
      .card-title {
        font-size: 22px;
      }
      .card-button {
        margin-bottom: 70%;
      }
    }
    .horizontal-snake {
      position: absolute;
      bottom: -41px;
      right: -30px;
      z-index: 100;
    }

    .vertical-snake {
      position: absolute;
      height: 100%;
      top: 0;
      right: 20px;
      width: 30%;
    }
  }
}
